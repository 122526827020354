<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.code" class="input" placeholder="包材编码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading" class="query-btn">查询</el-button>
      <el-button type="primary" @click="showAdd">添加</el-button>
    </el-row>
    <el-table :data="packMaterialList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="code" label="包材编码"></el-table-column>
      <el-table-column align="center" prop="name" label="名称"></el-table-column>
      <el-table-column align="center" prop="codeTypeName" label="编码类型"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80px"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150px"></el-table-column>
      <el-table-column align="center" label="操作" width="150px">
        <template slot-scope="scope">
          <el-button @click="deleteData(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog title="添加包材编码" :visible.sync="dialogVisible" :close-on-click-modal="false" width="600px" :before-close="handleClose">
      <el-form v-model="formData" label-width="150px">
        <el-form-item label="包材编码：">
          <el-input v-model.trim="formData.code" placeholder="请输入包材编码" class="input" clearable></el-input>
        </el-form-item>
        <el-form-item label="包材名称：">
          <el-input v-model.trim="formData.name" placeholder="请输入包材名称" class="input" clearable></el-input>
        </el-form-item>
        <el-form-item label="编码类型：">
          <el-select v-model="formData.codeType" placeholder="请选择编码类型" clearable class="input" filterable>
            <el-option v-for="item in packMaterialTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="padding-left: 80px">
        <span class="tip">提示：捷博内部编码是指捷博内部为了方便管理，给内部使用的包材、耗材编的码</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addPackMaterial">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "PackMaterialManage",
  data() {
    return {
      queryInfo: {
        code: '',
        pageNo: 1
      },
      formData: {
        code: '',
        name: '',
        codeType: '10'
      },
      loading: false,
      total: 0,
      pageSize: 0,
      dialogVisible: false,
      packMaterialList: []
    }
  },
  computed: {
    packMaterialTypeList() {
      return this.$store.getters.getDictList('packMaterialType') || [];
    }
  },
  created() {
    this.currentChange(1)
  },
  methods: {
    search() {
      this.loading = true;
      this.$axios.post('packMaterial/queryPage', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.packMaterialList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    showAdd() {
      this.formData.code = ''
      this.formData.name = ''
      this.formData.codeType = '10'
      this.dialogVisible = true
    },
    addPackMaterial() {
      if (!this.formData.code) {
        return this.$message.error('包材编码不能为空');
      }
      if (!this.formData.name) {
        return this.$message.error('包材名称不能为空');
      }

      this.loading = true;
      this.$axios.post('packMaterial/insert', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.$message.success("添加成功");
        this.currentChange(1);
        this.handleClose()
        this.formData.code = ''
        this.formData.name = ''
        this.formData.codeType = '10'

      }, error => {
        this.loading = false;
        return this.$message.error('添加失败，' + error.message);
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    deleteData(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('packMaterial/delete', row.code).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.currentChange(1);
        })
      })
    },
  }
}
</script>

<style scoped>
.input {
  width: 250px;
}

.query-btn {
  margin-left: 10px;
}
</style>